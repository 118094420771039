// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  // menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  skin: 'light',
  isOff: { status: false, canais: [] },
  loading: '{}',
  sidebar: true,
  onlineUsers: [],
  onlineUsersSearch: [],
  searchSidebar: '',
  groupFilas: [],
  groupEtiquetas: [],
  openModalGroupFilas: false,
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
      return { ...state, contentWidth: action.value }
    case 'HANDLE_MENU_COLLAPSED':
      window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    // case 'HANDLE_MENU_HIDDEN':
    //   return { ...state, menuHidden: action.value }
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value }
    case 'HANDLE_SKIN':
      return { ...state, skin: action.value }
    case 'HANDLE_ISOFF':
      return { ...state, isOff: action.value }
    case 'HANDLE_LOADING':
      return { ...state, loading: action.value }
    case 'HANDLE_SIDEBAR':
      return { ...state, sidebar: action.value }
    case 'HANDLE_ONLINE_USERS':
      return { ...state, onlineUsers: action.value }
    case 'HANDLE_ONLINE_USERS_SEARCH':
      return { ...state, onlineUsersSearch: action.value }
    case 'HANDLE_SEARCH_SIDEBAR':
      return { ...state, searchSidebar: action.value }
    case 'HANDLE_NUMBER_COPY':
      return { ...state, numberCopy: action.value }
    case 'HANDLE_GROUP_FILAS':
      return { ...state, groupFilas: action.value }
    case 'HANDLE_GROUP_ETIQUETAS':
      return { ...state, groupEtiquetas: action.value }
    case 'HANDLE_OPEN_MODAL_GROUP_FILAS':
      return { ...state, openModalGroupFilas: action.value }
    default:
      return state
  }
}

export default layoutReducer
