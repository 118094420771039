const stateUserSidebar = () => {
  const item = window.localStorage.getItem('stateUserSidebar')
  //** Parse stored json or if none return initialValue
  return item || 'info'
}

const initialState = {
  contacts: [],
  atendimentosAbertos: [],
  logados: [],
  closedContacts: [],
  calledClosedContacts: false,
  userProfile: {},
  selectedUser: {},
  messages: [],
  selectedCustomer: {},
  filasInfo: {},
  fila: 1,
  quickMessages: [],
  statusInstancia: 'connected',
  stateUserSidebar: stateUserSidebar(),
  unseenMsg: false,
  openAccordion: false,
  chatPage: 1,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return {
        ...state,
        contacts: action?.data?.contacts ?? [],
        unseenMsg: action?.data?.existsUnseenMsg
      }
    case 'UPDATE_CHAT_CONTACTS':
      let obj = { ...state, contacts: action.data.atendimentos }

      if (action.data?.existsUnseenMsg) {
        obj = { ...obj, unseenMsg: action.data.existsUnseenMsg }
      }
      return obj
    case 'MUDAR_FILA':
      return { ...state, fila: action.fila }
    case 'QUEUE_INFO':
      return { ...state, filasInfo: action.data }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'ATENDIMENTO_SELECIONADO':
      return { ...state, selectedCustomer: action.data }
    case 'ATENDIMENTOS_ABERTOS':
      return { ...state, atendimentosAbertos: action.data }
    case 'MESSAGES':
      return { ...state, messages: action.data }
    case 'STATUS_INSTANCIA':
      return { ...state, statusInstancia: action.value }
    case 'HANDLE_USER_SIDEBAR':
      window.localStorage.setItem('stateUserSidebar', action.value)
      return { ...state, stateUserSidebar: action.value }
    case 'UNSEEN_MSG':
      return { ...state, unseenMsg: action.data }
    case 'ONLINE_USERS':
      return { ...state, logados: action.value }
    case 'HANDLE_OPEN_ACCORDION':
      return { ...state, openAccordion: action.value }
    case 'CHAT_PAGE':
      return { ...state, chatPage: action.value }
    case 'QUICK_MESSAGE':
      return { ...state, quickMessages: action.value }
    default:
      return state
  }
}

export default chatReducer
