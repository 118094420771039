import { useContext } from 'react'
import { AbilityContext } from "./context/Can"
const moment = require("moment")
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { PhoneNumberUtil } from 'google-libphonenumber';
import { returnPermissionAbility } from '../configs/acl/initialAbility';
import { useSelector } from 'react-redux';
const phoneUtil = PhoneNumberUtil.getInstance();
export * from './playNotificationSounds'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {

  if (!value) return value
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatarHora = data => {

  let fechamentoConvertido = moment(data).utcOffset(-3).format()
  let a = moment(fechamentoConvertido)
  let today = moment().format()
  let b = moment(today)
  const h = b.diff(a, 'hours')
  return h
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  if (!value) {
    return
  }

  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'supervisor') return '/home'
  if (userRole === 'atendente') return '/lista-atendimento'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const toTitleCase = text => {
  return text.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export const isAdmin = () => {
  return returnPermissionAbility('manage', 'admin')
}

export const filterData = (array, category, inputTerm) => {
  const arr = []
  array.filter(item => Object.keys(item)
    .some(k => item[k] !== null &&
      (category.find(e => e === k)) &&
      item[k]?.toString()?.toLowerCase()
        .includes(inputTerm.replace(/[^\w\s]/gi, '').toLowerCase()) && arr.push(item)));

  return arr
}

export const isPossibleNumber = (number) => {
  let numberIsPossible = false

  const isValidNumber = value => {
    return /^\d+$/.test(value)
  }

  if (isValidNumber(number)) {
    try {
      const phoneNumber = phoneUtil.parse('+' + number, undefined);
      const region = phoneUtil.getRegionCodeForNumber(phoneNumber);
      const parsedNumber = phoneUtil.parse('+' + number, region);

      numberIsPossible = phoneUtil.isPossibleNumber(parsedNumber)
    } catch (error) {
      console.error('Is not a phone number')
    }
  }

  return numberIsPossible
}

export const formatPhoneNumber = (number) => {

  const isValidNumber = value => {
    return /^\d{5,}$/.test(value)
  }

  if (number && isValidNumber(number)) {

    try {
      const phoneNumber = phoneUtil.parse('+' + number, undefined);
      const region = phoneUtil.getRegionCodeForNumber(phoneNumber);

      if (region !== 'BR') {
        return number
      }

      const parsedNumber = phoneUtil.parse('+' + number, region);
      const numberType = phoneUtil.getNumberType(parsedNumber);

      if (numberType === -1) {
        // Se for número de celular
        const num = number.toString()

        if (num.length >= 12 && num.length <= 13 && num.substring(0, 2) === '55') {
          let regexNum = num.replace(/([5]{2})([0-9]{2})([0-9]+)([0-9]{4})/g, `($2) ${num.length === 12 ? '9' : ''}$3-$4`)
          return regexNum
        } else {
          return num
        }
      } else {
        // Se for número fixo
        const num = number.toString()
        let regexNum = num.replace(/([5]{2})([0-9]{2})([0-9]+)([0-9]{4})/g, `($2) $3-$4`)
        return regexNum
      }
    } catch (error) {
      console.error('Is not a phone number')
    }
  } else {
    // Se for um texto
    return number
  }
}

export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

export const hashGenerator = (length = 32) => {
  let result = '';
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getDeviceInfo = () => {
  const browser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Firefox") !== -1) return "Firefox";
    if (userAgent.indexOf("Chrome") !== -1) return "Chrome";
    if (userAgent.indexOf("Safari") !== -1) return "Safari";
    if (userAgent.indexOf("Edge") !== -1) return "Edge";
    if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) return "Internet Explorer";
    return "Other";
  };

  const os = () => {
    const userAgent = navigator.userAgent;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

    if (isMobile) {
      if (/iPhone/i.test(userAgent)) {
        return "iPhone";
      } else if (/Android/i.test(userAgent)) {
        return "Android";
      }
    }
    if (userAgent.indexOf("Win") !== -1) return "Windows";
    if (userAgent.indexOf("Mac") !== -1) return "MacOS";
    if (userAgent.indexOf("X11") !== -1) return "UNIX";
    if (userAgent.indexOf("Linux") !== -1) return "Linux";
    return "Other";
  };

  return `${browser()} - ${os()}`;
};

export const getDeviceId = async () => {
  let deviceId = localStorage.getItem('CD@device_id');

  if (!deviceId) {
    if (window.device && window.device.uuid) {
      deviceId = window.device.uuid;
    } else {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      deviceId = visitorId;
    }

    localStorage.setItem('CD@device_id', deviceId);
  }

  return deviceId;
}

export const checkIfIsOff = (channels) => {
  const { usuario } = getUserData()
  return {
    status: !!channels.perfis.find(item => item === usuario?.perfil_id),
    canais: channels.canais
  }
}

export const arrayToSelect = (array, label, value) => {
  return array.map(item => ({
    label: item[label],
    value: item[value]
  }))
}


export const isLightOrDark = (hex = '#fff') => {
  // const layoutStore = useSelector(state => state.layout);
  // const skin = layoutStore.skin;

  // console.log('SKIN ', skin, hex)

  // if(!hex) {
  //   hex = skin === 'light' ? '#fff': '#000'
  // }

  function hexToRgb(hex) {
    // Remove o caractere '#' se presente
    hex = hex?.replace(/^#/, '');

    // Converte os componentes de cor de hexadecimal para decimal
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
  }

  function brightnessFromRgb(r, g, b) {
    // Calcula o brilho usando a fórmula ponderada
    return 0.299 * r + 0.587 * g + 0.114 * b;
  }
  // Converte a cor hexadecimal para RGB
  let [r, g, b] = hexToRgb(hex);

  // Calcula o brilho
  let brightness = brightnessFromRgb(r, g, b);

  // Determina se a cor é clara ou escura
  return brightness > 127.5 ? 'light' : 'dark';
}

export const formatCpfCnpj = (documento) => {
  documento = documento?.replace(/\D/g, ""); // Remove tudo que não é dígito

  if (documento?.length === 11) {
    // Formatar CPF
    return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (documento?.length === 14) {
    // Formatar CNPJ
    return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else {
    return " ";
  }
}