import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["DEVELOPMENT", "HOMOLOGACAO", "PRODUCTION"],
  releaseStage: process.env.REACT_APP_ENV,
  appVersion: '1.0'
})

export default bugsnagClient