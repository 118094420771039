import encerraChamado from '../assets/audio/encerrou_chamado.wav'
import novaMsgAtendimento from '../assets/audio/nova_mensagem_atendimento.wav'
import novaMsgInterna from '../assets/audio/nova_mensagem_interna.wav'
import novoAtendimento from '../assets/audio/novo_atendimento_na_fila.wav'
import transfereAtendimento from '../assets/audio/transferiu_atendimento.wav'

export const playSound = type => {
  let file = ''
  switch (type) {
    case 'encerrou_chamado':
      file = encerraChamado
      break;
    case 'nova_mensagem_atendimento':
      file = novaMsgAtendimento
      break;
    case 'nova_mensagem_interna':
      file = novaMsgInterna
      break;
    case 'novo_atendimento_na_fila':
      file = novoAtendimento
      break;
    case 'transferiu_atendimento':
      file = transfereAtendimento
      break;
  }

  if (navigator?.userActivation?.hasBeenActive) {
    const audio = new Audio(file)
    audio.play().catch(() => {});
  }
}
