// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '../../views/pages/chat/store/reducer'
import internalChat from '../../views/pages/chat-interno/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  internalChat
})

export default rootReducer
