// ** React Imports
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

import bugsnagClient from './utility/bugsnagClient'
import BugsnagPerformance from '@bugsnag/browser-performance'

import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'

import { lazyRetry } from './utility/Utils'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './styles/global.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => lazyRetry(() => import('./App')))

const ErrorBoundary = bugsnagClient.getPlugin('react')
  .createErrorBoundary(React)

BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  enabledReleaseStages: ["DEVELOPMENT", "HOMOLOGACAO", "PRODUCTION"],
  releaseStage: process.env.REACT_APP_ENV
})

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer className="toast-container-global" newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </ErrorBoundary>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()