const initialState = {
  opened: false,
  lastMessage: {},
  infoMessageUser: [],
  infoUser: {},
  clickScrollToBottom: 0,
  notTheEnd: true,
}

const internalChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ABRE_CHAT':
      return { ...state, opened: action.value }
    case 'HANDLE_LAST_MESSAGE_INTERNAL_CHAT':
      return { ...state, lastMessage: action.value }
    case 'HANDLE_INFO_FOR_MESSAGE_USER':
      return { ...state, infoMessageUser: action.value }
    case 'HANDLE_INFO_USER':
      return { ...state, infoUser: action.value }
    case 'HANDLE_CLICK_SCROLL_TO_BOTTOM':
      return { ...state, clickScrollToBottom: action.value }
    case 'HANDLE_NOT_THE_END':
      return { ...state, notTheEnd: action.value }
    default:
      return state
  }
}

export default internalChatReducer
