import { defineAbility } from '@casl/ability';

// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'atendente'
  }
]

const Sudo = defineAbility((can, cannot) => {
  can('manage', 'sudo');
  can('read', 'sudo');
  can('manage', 'admin');
  can('read', 'admin');
  can('manage', 'supervisor');
  can('read', 'atendente');
});

const Admin = defineAbility((can, cannot) => {
  can('manage', 'admin');
  can('read', 'admin');
  can('manage', 'supervisor');
  can('read', 'atendente');
});

const Supervisor = defineAbility((can, cannot) => {
  can('manage', 'supervisor');
  can('read', 'atendente');
  can('read', 'Auth');
});

const Atendente = defineAbility((can, cannot) => {
  can('read', 'atendente');
  can('read', 'Auth');
});

const returnPermissionAbility = (action, resource) => {
  const userData = localStorage.getItem('userData')
  const user = JSON.parse(userData);

  switch (user?.usuario?.role) {
    case 'Sudo':
      return Sudo.can(action, resource)

    case 'Administrador':
      return Admin.can(action, resource)

    case 'Supervisor':
      return Supervisor.can(action, resource)

    case 'Atendente':
      return Atendente.can(action, resource)

    default:
      return;
  }
}

export { Sudo, Admin, Supervisor, Atendente, returnPermissionAbility }
